import request from '@/utils/request'


// 查询会员菜单列表
export function listMenu(query) {
  return request({
    url: '/vip/vip-menus/list',
    method: 'get',
    params: query
  })
}

// 查询会员菜单分页
export function pageMenu(query) {
  return request({
    url: '/vip/vip-menus/page',
    method: 'get',
    params: query
  })
}

export function changeIsOpen(data) {
  return request({
    url: '/vip/vip-menus/changeIsOpen',
    method: 'post',
    data: data
  })
}

// 查询会员菜单详细
export function getMenu(data) {
  return request({
    url: '/vip/vip-menus/detail',
    method: 'get',
    params: data
  })
}

// 新增会员菜单
export function addMenu(data) {
  return request({
    url: '/vip/vip-menus/add',
    method: 'post',
    data: data
  })
}

// 修改会员菜单
export function updateMenu(data) {
  return request({
    url: '/vip/vip-menus/edit',
    method: 'post',
    data: data
  })
}

// 删除会员菜单
export function delMenu(data) {
  return request({
    url: '/vip/vip-menus/delete',
    method: 'post',
    data: data
  })
}
